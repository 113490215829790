@import '../../../../styles/customMediaQueries.css';

.root {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: auto;
  padding: 24px;
}

.content {
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 24px;
  background-color: var(--colorWhite);
  box-shadow: var(--boxShadowTop);
  /* display: none; */

  &>div {
    & a {
      background-color: #BF5C3A;
      min-height: 40px;
      width: 100%;
      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      color: #fff;
      font-size: 20px;
    }

    &>div {
      & :nth-child(2) {
        background-color: #BF5C3A;
        min-height: 40px;
        width: 100%;
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: #fff;
        font-size: 20px;
      }
    }
  }


}

.avatar {
  /* Layout */
  flex-shrink: 0;
  margin: var(--TopbarMobileMenu_topMargin) 0 0 0;
}

.greeting {
  /* Font */
  composes: h1 from global;
  margin-bottom: 1px;
  margin-top: 16px;
}

.logoutButton {
  /* Position and dimensions */
  display: inline;

  /* Borders */
  border: none;

  /* Text size should be inherited */
  text-decoration: none;

  /* Logout font is smaller and gray since the action is not recommended. */
  font-weight: var(--fontWeightSemiBold);
  font-size: 18px;
  line-height: 24px;

  color: var(--colorGrey300);

  /* Position component */
  width: initial;
  margin: 4px 0 24px 0;
  padding: 0;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportMedium) {
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    margin-top: 16px;
    margin-bottom: 16px;
  }
}

.inbox {
  /* Font */
  composes: h2 from global;
  position: relative;

  font: normal normal bold 18px/32px Roc Grotesk;
  letter-spacing: 0px;
  color: #3A3D3A;
  margin-top: auto;
  margin-bottom: 13px;
}

.navigationLink {
  /* Font */
  composes: h2 from global;
  font: normal normal bold 18px/32px Roc Grotesk;
  letter-spacing: 0px;
  color: #3A3D3A;

  /* Layout */
  margin-top: 0;
  margin-bottom: 11px;

  & .badge {
    color: var(--matterColorLight);
    text-align: center;
    background-color: var(--failColor);
    display: inline-flex;
    width: 20px;
    min-width: 20px;
    height: 20px;
    border-radius: 10px;
    padding: 0;
    margin-left: 4px;
    font-size: 14px;
    font-weight: normal;
    margin-top: 0;
    margin-bottom: 0;
    align-items: center;
    justify-content: center;
  }
}

.currentPageLink {
  color: var(--marketplaceColorDark);
}

.notificationBadge {
  position: absolute;
  top: 4px;
  right: -26px;

  /* This is inside a link - remove underline */
  &:hover {
    text-decoration: none;
  }
}

.createNewListingLink {
  composes: buttonPrimary from global;
}

.authenticationGreeting {
  /* Font */
  composes: h1 from global;
  line-height: 92px;
  margin-bottom: 24px;
  margin-top: var(--TopbarMobileMenu_topMargin);

  @media (max-width: 767px) {
    line-height: 38px;
  }
}

.authenticationLinks {
  white-space: nowrap;
}

.signupLink {
  text-decoration: none;
  white-space: nowrap;
  color: #BF5C3A;
}

.loginLink {
  text-decoration: none;
  white-space: nowrap;
  color: #BF5C3A;
}

.currentPage {
  color: var(--colorBlack);

  /* black left border */
  border-left: 5px solid black;
  margin-left: -24px;
  padding-left: 19px;
}

.spacer {
  width: 100%;
  height: 124px;
}

.cardBox {
  position: relative;

  & a {
    display: flex;
    align-items: center;
  }

  & .cartCountMobile {
    background-color: #e12222;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: 1px solid #e12222;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    margin-left: 5px;
    margin-top: -5px;
  }
}

.disable {
  pointer-events: none;
  background: #c0c0c0;
}

.subscribeFirstModal {
  z-index: 9999999;
  /* &>div>div {
    & button {
      display: none;
    }
  } */
}
.termsWrapper{
  padding-top: 50px;
}